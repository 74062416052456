
const menuItems = document.querySelectorAll(".nav__ul > .menu-item-has-children .parent" );

if ( menuItems.length > 0 && window.innerWidth <= 1023 ) {
  for ( let item of menuItems ) {
    item.addEventListener('click', (e) => {
      const open = document.querySelector( ".nav__ul .mobile-open" );
      if ( open && open !== item.parentElement ) {
        open.classList.remove('mobile-open');
      }
      e.preventDefault();
      item.parentElement.classList.toggle('mobile-open');
  })
  }
}