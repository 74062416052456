let panels = document.querySelectorAll(".panel");

function initCollapse() {
  // Exit the function if no panels's are on page.
  if (!panels) return;

  // Loop through all panels's to add the click listener.
  for (let panel of panels) {
    panel.querySelector(".expand").addEventListener("click", (e) => {
      panel.classList.toggle("open");
    });
  }
}

initCollapse();
